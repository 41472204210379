import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class Customer extends Model {
	protected jsonApiType = 'accounting/customers'
	protected static pageSize = 10

	@Property()
	public name!: string

	@Property()
	public email!: string

	@Property()
	public reference!: string | null

	@Property()
	public relationTypes!: Array<string>

	public static async fetchLazy(id): Promise<Customer> {
		const store = (await import('@/store/index')).store
		if (!store.getters['accountingCustomers/hasCached'](id)) {
			await store.dispatch('accountingCustomers/findRecord', id)
		}

		return store.getters['accountingCustomers/peekRecord'](id)
	}
}
