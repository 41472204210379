import AuthState from '@/interfaces/State/AuthState'
import { Company } from '@/models/Company'
import { EmptyVessel } from '@/models/EmptyVessel'
import { Membership } from '@/models/Membership'
import FinancialConnection from '@/models/FinancialConnection'

export const setInitialized = (state: AuthState, initialized): void => {
	state.initialized = initialized
}

export const setUser = (
	state: AuthState,
	user: {
		id
		email
		companyName
		role
		firstName
		lastName
		active: boolean
		isSuperUser: boolean
	}
): void => {
	state.user = user
}

export const setVessels = (state: AuthState, vessels: Array<string>): void => {
	state.vessels = vessels
}

export const setCompanies = (state: AuthState, companies: Array<Company>): void => {
	state.companies = companies
}

export const setPermissions = (state: AuthState, permissions: Array<string>): void => {
	state.permissions = permissions
}

export const setEmptyVessel = (state: AuthState, empty: EmptyVessel | null): void => {
	state.emptyVessel = empty
}

export const setQueuedEmptyVessel = (state: AuthState, queuedEmpty: EmptyVessel | null): void => {
	state.queuedEmptyVessel = queuedEmpty
}

export const setUserMemberships = (state: AuthState, memberships: Array<Membership>): void => {
	state.memberships = memberships.map((membership) => {
		return membership.slug
	})
}

export const setFinancialConnection = (state: AuthState, connection: FinancialConnection): void => {
	state.financialConnection = connection
}
