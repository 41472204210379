import { Model } from '@/models/Model'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Cargo } from '@/models/Cargo'
import Property from '@/decorators/Property'
import axios from 'axios'
import { Company } from '@/models/Company'

export class Order extends Model {
	protected jsonApiType = 'orders'

	@Property()
	public pdfPath!: string

	@Property()
	public previewPath!: string

	@Property()
	public fileName!: string

	@Property()
	public sendEmail: boolean | undefined

	@Property()
	public sendEmailCopy: boolean | undefined

	@Property()
	public emailBody: string | null | undefined

	@Property()
	public emailSubject: string | null | undefined

	@Property()
	public receiverEmails: string | null | undefined

	private cargo(): ToOneRelation {
		return this.hasOne(Cargo, 'cargo')
	}

	public getCargo(): Cargo {
		return this.getRelation('cargo')
	}

	private client(): ToOneRelation {
		return this.hasOne(Company, 'client')
	}

	public getClient(): Company {
		return this.getRelation('client')
	}

	public async getPreview(): Promise<string> {
		const response = await axios.get<string>(this.previewPath, {
			responseType: 'text'
		});

        return response.data;
	}

	public get sanitizedFilename() {
		const splitted = (this.fileName || '').split('/')
		return splitted[splitted.length - 1] + '.pdf'
	}
}
