import { Cargo } from '@/models/Cargo'
import { Customer } from '@/models/accounting/Customer'
import { Document } from '@/models/Document'
import FinancialType from '@/enums/FinancialType'
import { FlatTransport } from '@/models/FlatTransport'
import { InvoiceLine } from '@/models/accounting/InvoiceLine'
import InvoiceStatus from '@/enums/InvoiceStatus'
import InvoiceType from '@/enums/InvoiceType'
import { Journal } from '@/models/accounting/Journal'
import { Model } from '@/models/Model'
import { PluralResponse } from 'coloquent'
import Property from '@/decorators/Property'
import { Sends } from '@/models/Sends'
import { ToManyRelation } from 'coloquent/dist/relation/ToManyRelation'
import { ToOneRelation } from 'coloquent/dist/relation/ToOneRelation'
import { Transport } from '@/models/Transport'
import TransportStatus from '@/enums/TransportStatus'

export class Invoice extends Model {
	protected jsonApiType = 'accounting/invoices'
	protected static pageSize = 10

	@Property()
	public transportStatus!: TransportStatus | null

	@Property()
	public fullInvoiceNumber!: string | null

	@Property()
	public invoiceNumber!: number | null

	@Property()
	public invoiceDate!: string | null

	@Property()
	public paymentCondition!: number

	@Property()
	public description!: string

	@Property()
	public reference!: string

	@Property()
	public amount!: number

	@Property()
	public cargoProduct!: string

	@Property()
	public status!: InvoiceStatus

	@Property()
	public invoiceType!: InvoiceType

	@Property()
	public isExternal!: boolean

	@Property()
	public amountExclVat!: number

	@Property()
	public sendEmail: boolean | undefined

	@Property()
	public sendEmailCopy: boolean | undefined

	@Property()
	public emailBody: string | null | undefined

	@Property()
	public emailSubject: string | null | undefined

	@Property()
	public emailFrom: string | null | undefined

	@Property()
	public emailReplyTo: string | null | undefined

	@Property()
	public receiverEmails: string | null | undefined

	public fetchingInvoiceLines: boolean = false
	public generatingPdf: boolean = false
	public mailingPdf: boolean = false
	public crediting: boolean = false
	public savingStatus: boolean = false

	private pdf(): ToOneRelation {
		return this.hasOne(Document, 'pdf')
	}

	public getPdf(): Document {
		return this.getRelation('pdf')
	}

	public setPdf(pdf: Document) {
		this.setRelation('pdf', pdf)
	}

	private customer(): ToOneRelation {
		return this.hasOne(Customer, 'customer')
	}

	public getCustomer(): Customer {
		return this.getRelation('customer')
	}

	public setCustomer(customer: Customer | null) {
		this.setRelation('customer', customer)
	}

	private journal(): ToOneRelation {
		return this.hasOne(Journal, 'journal')
	}

	public getJournal(): Journal {
		return this.getRelation('journal')
	}

	public setJournal(journal: Journal) {
		this.setRelation('journal', journal)
	}

	public documents(): ToManyRelation {
		return this.hasMany(Document, 'documents')
	}

	public invoiceLines(): ToManyRelation {
		return this.hasMany(InvoiceLine, 'invoiceLines')
	}

	public getInvoiceLines(): Array<InvoiceLine> {
		return this.getRelation('invoiceLines') || []
	}

	public setInvoiceLines(lines: Array<InvoiceLine>) {
		this.setRelation('invoiceLines', lines)
	}

	public async fetchInvoiceLines() {
		const invoiceLines = ((await this.invoiceLines().get()) as PluralResponse)
			.getData()
			.reverse() as Array<InvoiceLine>
		this.setInvoiceLines(invoiceLines)
	}

	public transports(): ToManyRelation {
		return this.hasMany(Transport, 'transports')
	}

	public getTransports(): Array<Transport> {
		return this.getRelation('transports') || []
	}

	public setTransports(transports: Array<Transport> | Array<FlatTransport>) {
		this.setRelation('transports', transports)
	}

	public latestSend(): ToOneRelation {
		return this.hasOne(Sends, 'latestSend')
	}

	public getLatestSend(): Sends {
		return this.getRelation('latestSend')
	}

	public setStatus(status: InvoiceStatus) {
		this.status = status
	}

	get isEditable(): boolean {
		return this.status === InvoiceStatus.OPEN
	}

	get hasInvoiceNumber(): boolean {
		return [InvoiceStatus.GENERATED, InvoiceStatus.SENT, InvoiceStatus.CLOSED].includes(this.status)
	}

	public get sanitizedFilename() {
		const splitted = (this.getPdf().fileName || '').split('/')
		return splitted[splitted.length - 1]
	}

	public static createNewFromTransport(
		transport: Transport | null,
		cargo: Cargo,
		invoiceType: InvoiceType,
		customer: Customer | null = null
	) {
		const invoice = new Invoice()
		invoice.invoiceType = invoiceType
		invoice.paymentCondition = 30
		if (customer) {
			invoice.setCustomer(customer)
		} else {
			if (invoiceType === InvoiceType.SALES) {
				if (cargo.getClient()) {
					const selectedCustomer = new Customer()
					selectedCustomer.setApiId(cargo.getClient()?.getApiId())
					selectedCustomer.name = cargo.getClient()?.name || ''
					invoice.setCustomer(selectedCustomer)
				}
				invoice.reference = cargo.clientOrderReference || ''
			} else if (invoiceType === InvoiceType.PURCHASE && transport) {
				let creditor
				if (transport.getOffer()?.getViaBroker()) {
					creditor = transport.getOffer()?.getViaBroker()
				} else {
					creditor = transport.getActiveVessel()?.getCompany()
				}
				if (creditor) {
					const selectedCustomer = new Customer()
					selectedCustomer.setApiId(creditor.getApiId())
					selectedCustomer.name = creditor.name || ''
					invoice.setCustomer(selectedCustomer)
				}
			}
		}
		const terms =
			invoiceType === InvoiceType.SALES
				? cargo.getOrderTerms()
				: invoiceType === InvoiceType.PURCHASE && transport
				  ? cargo.getCharterTerms()
				  : null
		if (terms) {
			invoice.paymentCondition = terms.termOfPaymentDays || invoice.paymentCondition
		}
		invoice.status = InvoiceStatus.OPEN
		return invoice
	}

	public static makeInvoiceLinesFromFinancialRegistrations(lines: Array<InvoiceLine>): Array<InvoiceLine> {
		const copiedLines = lines.map((line) => {
			const copiedLine = new InvoiceLine()
			copiedLine.financialType = FinancialType.INVOICE_LINE
			copiedLine.invoiceType = line.invoiceType
			copiedLine.setPrecededBy(line)
			copiedLine.quantity = line.quantity
			copiedLine.unitPrice = line.unitPrice
			copiedLine.description = line.description
			copiedLine.customProduct = line.customProduct
			copiedLine.setVatCode(line.getVatCode())
			copiedLine.setProduct(line.getProduct())
			copiedLine.setTransport(line.getTransport())
			copiedLine.setCustomer(line.getCustomer())
			return copiedLine
		})

		// correctly set priceDependentOn relation to copied line
		copiedLines.forEach((line) => {
			const financialRegistration = line.getPrecededBy()
			if (financialRegistration?.getPriceDependentOn().length) {
				line.setPriceDependentOn(
					// @ts-ignore
					financialRegistration
						.getPriceDependentOn()
						.map((dependentOn) => {
							return copiedLines.find((invoiceLine) => {
								return invoiceLine.getPrecededBy() === dependentOn
							})
						})
						.filter(Boolean)
				)
			}
		})

		return copiedLines
	}

	public static getLabelColor(status: InvoiceStatus) {
		if (status === InvoiceStatus.PAID) return 'green lighten-4'
		if (status === InvoiceStatus.SENT) return 'yellow lighten-4'
		if (status === InvoiceStatus.GENERATED) return 'blue lighten-5'
		if (status === InvoiceStatus.OPEN) return 'grey lighten-4'
		return ''
	}
}
