import { MapState } from '@/interfaces/MapState'

export const state: MapState = {
	activeFacility: null,
	boundingBox: null,
	facilities: [],
	vessels: [],
	query: null,
	routes: [],
	route: null,
	routesLoading: false
}
