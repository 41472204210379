import { Model } from '@/models/Model'
import Property from '@/decorators/Property'

export class CargoType extends Model {
	protected jsonApiType = 'cargoTypes'

	public get title(): string {
		return [this.description, this.cdniNumber ? `(${this.cdniNumber})` : null].filter(Boolean).join(' ')
	}

	@Property()
	public label!: string

	@Property()
	public bulkType!: string

	@Property()
	public cargoTypePercentage!: string

	@Property()
	public cdniNumber!: string

	@Property()
	public description!: string

	@Property()
	public maxCargoTypePercentage!: number

	@Property()
	public minCargoTypePercentage!: number

	@Property()
	public pieceGoods!: string

	@Property()
	public weightMeasurements!: string

	public static async fetchAllLazy(): Promise<Array<CargoType>> {
		const store = (await import('@/store/index')).store
		if (!store.getters['cargoTypes/hasAllFetched']()) {
			await store.dispatch('cargoTypes/findAll')
		}

		return store.getters['cargoTypes/peekAll']()
	}

	public static async fetchLazy(id): Promise<CargoType> {
		const store = (await import('@/store/index')).store
		if (!store.getters['cargoTypes/hasCached'](id)) {
			await store.dispatch('cargoTypes/findRecord', id)
		}

		return store.getters['cargoTypes/peekRecord'](id)
	}
}
