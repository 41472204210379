import auth from './auth'
import map from './map'
import messages from './messages'
import viewport from './viewport'
import notifications from './notifications'
import vessels from './vessels'
import users from './users'
import dashboard from './dashboard'
import countries from './countries'
import phoneCountryCodes from './phoneCountryCodes'
import transferTimes from './transferTimes'
import cargoTypes from './cargoTypes'
import cargoHoldFloors from './cargoHoldFloors'
import berthFees from './berthFees'
import additions from './additions'
import certificationClaimTypes from './certificationClaimTypes'
import transportDetails from './transportDetails'
import vesselDetails from './vesselDetails'
import attributes from './attributes'
import terminals from './terminals'
import companies from './companies'
import cargos from './cargos'
import offers from './offers'
import conversations from './conversations'
import cargoOverview from './cargoOverview'
import accountingProducts from './accounting/products'
import accountingVatCodes from './accounting/vatCodes'
import accountingJournals from './accounting/journals'
import accountingInvoices from './accounting/invoices'
import accountingCustomers from './accounting/customers'
import transports from './transports'
import flatTransports from './flatTransports'
import cargoProjects from './cargoProjects'
import clients from './clients'
import brokers from './brokers'
import filter from './filter'
import billOfLadings from './billOfLadings'
import documents from './documents'
export default {
	auth,
	map,
	viewport,
	messages,
	notifications,
	vessels,
	users,
	dashboard,
	countries,
	phoneCountryCodes,
	transferTimes,
	cargoTypes,
	cargoHoldFloors,
	berthFees,
	additions,
	certificationClaimTypes,
	transportDetails,
	vesselDetails,
	attributes,
	terminals,
	companies,
	cargos,
	offers,
	conversations,
	cargoOverview,
	accountingProducts,
	accountingVatCodes,
	accountingJournals,
	accountingInvoices,
	accountingCustomers,
	transports,
	flatTransports,
	cargoProjects,
	clients,
	brokers,
	filter,
	billOfLadings,
	documents
}
