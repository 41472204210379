
import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Icon from '@/components/Icon.vue'
import MobileNavigationItem from '@/components/Navigation/MobileNavigationItem.vue'
import ContactDialog from '@/components/ContactDialog.vue'

const Auth = namespace('auth')
const Viewport = namespace('viewport')
const MAX_NAVIGATION_ITEMS = 4

@Component({
	components: {
		MobileNavigationItem,
		Icon,
		ContactDialog
	}
})
export default class MobileNavigation extends Vue {
	@Auth.Getter('navigationItems')
	navigationItems

	@Viewport.Getter('isMobile')
	isMobile

	contactDialogOpened: boolean = false

	showSheet: boolean = false

	toggleSheet() {
		this.showSheet = !this.showSheet
	}

	get numberOfShownItems() {
		return MAX_NAVIGATION_ITEMS
	}

	get sheetIsActive() {
		return this.$route.name === 'favourite-groups'
	}

	@Auth.Action('logout')
	logout: any
}
